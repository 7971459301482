import { cn } from '@/Lib'
import type { FC, ReactNode } from 'react'

// TODO: refactor children to use label prop rather.
type IconProps = {
  /**
   * The name of the material icon:
   * https://fonts.google.com/icons
   */
  children?: ReactNode
  className?: string
  large?: boolean
}

export const Icon: FC<IconProps> = ({ className, children, large = false }) => {
  return (
    // TODO Add sr-only label based on children
    <span
      className={cn(
        'inline-block select-none whitespace-nowrap fill-current text-center font-normal normal-case not-italic tracking-normal antialiased',
        large
          ? 'size-12 font-icon-large text-5xl leading-[48px]'
          : 'size-6 font-icon text-2xl leading-6',
        className
      )}
    >
      {children}
    </span>
  )
}
